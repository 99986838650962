import '../App.css'
import React, { useState, useEffect } from 'react';
import { Container, CardDeck, Card, Row, Col, Button, Navbar, Nav, NavbarBrand, NavItem, NavLink, NavbarToggler, Collapse } from 'reactstrap';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';


import backgroundImage from '../images/mosque-hero.png';
import logoTrans from '../images/logo-trans.png';
import logoIcon from '../images/icon.png'
import quranAiPreview from '../images/quran-ai-preview.png'
import virtualImamPreview from '../images/virtual-imam-preview.png'
import foodScanner from '../images/food-scanner.png'
import qiblaCompass from '../images/qibla-compass.png'
import tasbih from '../images/tasbih.png'
import prayerTime from '../images/prayer-time.png'
import dailyDua from '../images/daily-dua.png'
import mosqueFinder from '../images/mosque-finder.png'
import discoverPreview from '../images/discover-preview.png'
import halalCheck from '../images/halal-check.png'
import apple from '../images/app-store-pre-order.png'
import android from '../images/android-coming-soon.png'
const TopNav = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div style={{ borderBottom: '2px solid #67B646' }}>
        <Navbar light expand="md" >
          <NavbarBrand>
            <img src={logoIcon} style={{ transform: 'scale(1.8)' }}></img>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink type="button">
                  <span
                    onClick={() => document.getElementById('askTheQuran').scrollIntoView({ behavior: 'smooth' })}
                    className="text-black font-weight-bold reg-text">
                    QuranAI
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink type="button">
                  <span
                    onClick={() => document.getElementById('getGuidance').scrollIntoView({ behavior: 'smooth' })}
                    className="text-black font-weight-bold reg-text">Virtual Imam </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink type="button">
                  <span
                    onClick={() => document.getElementById('featureRich').scrollIntoView({ behavior: 'smooth' })}
                    className="text-black font-weight-bold reg-text">Features </span>
                </NavLink>
              </NavItem>
              <NavItem>
                {/* <Link to="/demo-starter">
                  <NavLink type="button">
                    <span
                      className="text-black font-weight-bold reg-text">Demo </span>
                  </NavLink>
                </Link> */}
              </NavItem>
            </Nav>
            <Nav className="ml-auto" navbar>
              {/* <a href="https://www.imamai.app/waitlist" target="_blank">
                <Button className="font-weight-bold reg-text rounded-pill green-bg">Get notified!</Button>
              </a> */}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </>
  );
}


const Home = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []); // Empty array means this effect runs once on mount and clean up on unmount


  return (
    <ParallaxProvider>
      <div>
        <TopNav />

        {windowWidth <= 768 ? (
          <div className="text-center pt-7 pb-0 mosque-bg">
            <Container>
              <p className="pt-5 text-black light-text" style={{ fontSize: '2em', lineHeight: '1.2' }}>ImamAI helps you <br /> learn & grow</p>
              <p className="text-black light-text" style={{ fontSize: '1.3em' }}>Ask questions directly to the Quran, track your prayers, connect with your faith in a more meaningful way. </p>
              <br /><br />
              <a href="https://apps.apple.com/us/app/imamai-ask-the-quran/id6450143012" target="_blank">
                <img src={apple} width="200"></img>
              </a>
              <img src={android} width="250"></img>
              <br /><br />
            </Container>
          </div>
        ) :
          (
            <div className="text-center pt-7 pb-0 mosque-bg">
              <Container style={{ width: '30vw' }}>
                <p className="pt-5 text-black light-text" style={{ fontSize: '3em', lineHeight: '1.2' }}>ImamAI helps you <br /> learn & grow</p>
                <p className="text-black light-text" style={{ fontSize: '1.3em' }}>Ask questions directly to the Quran, track your prayers, connect with your faith in a more meaningful way. </p>
                <br /><br />
                <a href="https://apps.apple.com/us/app/imamai-ask-the-quran/id6450143012" target="_blank">
                  <img src={apple} width="200"></img>
                </a>
                <img src={android} width="250"></img>
                <br /><br />
              </Container>
            </div>
          )
        }

        <div body className="py-2 mosque-bg-2 green-bg text-center">
          <div className="text-center">
            {
              windowWidth <= 768 ?
                <>
                  <p className="px-1 heavy-text text-white" style={{ fontSize: '1.5em' }}>The world's first-ever online prayer leader.</p></> :
                <>
                  <p className="heavy-text text-white" style={{ fontSize: '2.5em' }}>The world's first-ever online prayer leader.</p></>
            }
          </div>
        </div>
        <div style={{ backgroundColor: "white" }}>
          <Container>
            <CardDeck className="py-5">
              <Card className="transparent-card p-2">
                <img src={quranAiPreview}></img>

              </Card>
              <Card className="transparent-card p-4">
                <small id="askTheQuran" className="reg-text green-text" style={{ lineHeight: '3' }}>ASK THE QURAN</small>
                <p className="reg-text text-black" style={{ fontSize: '3em', lineHeight: '1' }}>QuranAI</p>
                <p className="light-text text-black" style={{ fontSize: '1.1em' }}>
                  Welcome to QuranAI, the smart way to explore the Quran. We've packed advanced AI technology into an easy-to-use app that answers your questions about the Quran. You can dig deep into verses, get to know the historical background, and much more. QuranAI is for everyone - whether you're studying, curious, or just want to learn. Join us on a new, innovative journey to understand the Quran.
                </p>
                {/* <a href="https://imamai.app/waitlist" target="_blank">
                  <Button className="shadow reg-text rounded-pill px-4 py-3 green-bg" style={{ backgroundColor: 'black', color: 'green-text' }}>Sign up for email updates</Button>
                </a> */}
              </Card>
            </CardDeck>
            <CardDeck className="py-5" id="getGuidance">
              {windowWidth <= 768 ? (
                <>
                  <Card className="transparent-card">
                    <img src={virtualImamPreview}></img>
                  </Card>
                  <Card className="transparent-card ">
                    <small className="reg-text green-text" style={{ lineHeight: '3' }}>GET GUIDANCE</small>
                    <p className="reg-text text-black" style={{ fontSize: '3em', lineHeight: '1' }}>Virtual Imam</p>
                    <p className="light-text text-black" style={{ fontSize: '1.1em' }}>
                      Introducing Virtual Imam, your digital guide for prayers. Utilizing cutting-edge AI technology, Virtual Imam is designed to walk you through your prayers step-by-step. Whether you're a beginner needing assistance or a regular praying individual seeking guidance, Virtual Imam adapts to your pace. Experience an innovative, personal and accessible way to fulfill your spiritual practice with Virtual Imam.
                    </p>
                    {/* <a href="https://imamai.app/waitlist" target="_blank">
                      <Button className="shadow reg-text rounded-pill px-4 py-3 green-bg" style={{ backgroundColor: 'black', color: 'green-text' }}>Pre-order for $5!</Button>
                    </a> */}
                  </Card>
                </>

              ) : (
                <>
                  <Card className="transparent-card p-4">
                    <small className="reg-text green-text" style={{ lineHeight: '3' }}>GET GUIDANCE</small>
                    <p className="reg-text text-black" style={{ fontSize: '3em', lineHeight: '1' }}>Virtual Imam</p>
                    <p className="light-text text-black" style={{ fontSize: '1.1em' }}>
                      Introducing Virtual Imam, your digital guide for prayers. Utilizing cutting-edge AI technology, Virtual Imam is designed to walk you through your prayers step-by-step. Whether you're a beginner needing assistance or a regular praying individual seeking guidance, Virtual Imam adapts to your pace. Experience an innovative, personal and accessible way to fulfill your spiritual practice with Virtual Imam.
                    </p>
                    {/* <a href="https://imamai.app/waitlist" target="_blank">
                      <Button className="shadow reg-text rounded-pill px-4 py-3 green-bg" style={{ backgroundColor: 'black', color: 'green-text' }}>Pre-order for $5!</Button>
                    </a> */}
                  </Card>
                  <Card className="transparent-card p-2">
                    <img src={virtualImamPreview}></img>
                  </Card>
                </>
              )}

            </CardDeck>
          </Container>
        </div>
        <div className="tan-bg">
          <Container className="p-4 text-center">
            <small id="featureRich" className="reg-text green-text" style={{ lineHeight: '1' }}>FEATURE RICH</small>
            <p className="reg-text text-black " style={{ fontSize: windowWidth <= 768 ? '2em' : '3em' }}>Comprehensive and easy-to-use.</p>
            <CardDeck>
              <Card className="transparent-card" body>
                <img style={{ borderRadius: '50%', border: '5px solid #67B646 ' }} src={discoverPreview}></img>
                <p className="reg-text text-black pt-3" style={{ fontSize: '1.3em', lineHeight: '1' }}>Discover</p>
                <p className="light-text text-black" style={{ fontSize: '.8em', lineHeight: '1' }}>Get instant, clear answers to all your Quran-related queries with our Divine Decoder.</p>
              </Card>
              <Card className="transparent-card" body>
                <img style={{ borderRadius: '50%', border: '5px solid #67B646 ' }} src={dailyDua}></img>
                <p className="reg-text text-black pt-3" style={{ fontSize: '1.3em', lineHeight: '1' }}>Daily Dua</p>
                <p className="light-text text-black" style={{ fontSize: '.8em', lineHeight: '1' }}>Experience the uplifting Daily Dua feature, providing you with a unique prayer every day. </p>
              </Card>
              <Card className="transparent-card" body>
                <img style={{ borderRadius: '50%', border: '5px solid #67B646 ' }} src={prayerTime}></img>
                <p className="reg-text text-black pt-3" style={{ fontSize: '1.3em', lineHeight: '1' }}>Prayer Times</p>
                <p className="light-text text-black" style={{ fontSize: '.8em', lineHeight: '1' }}>Stay aligned with your prayer schedule with our Prayer Reminder.</p>
              </Card>
              <Card className="transparent-card" body>
                <img style={{ borderRadius: '50%', border: '5px solid #67B646 ' }} src={tasbih}></img>
                <p className="reg-text text-black pt-3" style={{ fontSize: '1.3em', lineHeight: '1' }}>Digital Tasbih</p>
                <p className="light-text text-black" style={{ fontSize: '.8em', lineHeight: '1' }}>Experience the peace and rhythm of the traditional tasbih prayer beads reimagined in a sleek, digital format right at your fingertips, with our new app's innovative Digital Tasbih feature.</p>
              </Card>

            </CardDeck>
            <CardDeck>
              <Card className="transparent-card" body>
                <img style={{ borderRadius: '50%', border: '5px solid #67B646 ' }} src={qiblaCompass}></img>
                <p className="reg-text text-black pt-3" style={{ fontSize: '1.3em', lineHeight: '1' }}>Qibla Compass</p>
              </Card>
              <Card className="transparent-card" body>
                <img style={{ borderRadius: '50%', border: '5px solid #67B646 ' }} src={mosqueFinder}></img>
                <p className="reg-text text-black pt-3" style={{ fontSize: '1.3em', lineHeight: '1' }}>Mosque Finder</p>
              </Card>
              <Card className="transparent-card" body>
                <img style={{ borderRadius: '50%', border: '5px solid #67B646 ' }} src={halalCheck}></img>
                <p className="reg-text text-black pt-3" style={{ fontSize: '1.3em', lineHeight: '1' }}>Halal Check </p>
              </Card>
              <Card className="transparent-card" body>
                <img style={{ borderRadius: '50%', border: '5px solid #67B646 ' }} src={foodScanner}></img>
                <p className="reg-text text-black pt-3" style={{ fontSize: '1.3em', lineHeight: '1' }}>Food Scanner</p>
              </Card>
            </CardDeck>

            <small className="reg-text green-text" style={{ lineHeight: '1' }}>GLOBAL MUSLIM POPULATION</small>
            {
              windowWidth <= 768 ?
                <>
                  <p className="reg-text light-green-text " style={{ fontSize: '2em' }}>2,000,000,000+</p>
                </> :
                <>
                  <p className="reg-text light-green-text " style={{ fontSize: '4em' }}>2,000,000,000+</p>
                </>
            }
          </Container>
        </div>
        <div>
          <Container className="p-4 ">
            <Card body className="mosque-bg-2 green-bg text-center" style={{ borderRadius: '30px' }}>
              <p className=" reg-text text-white">HELP US LAUNCH</p>
              <div className="text-center">
                {
                  windowWidth <= 768 ?
                    <>
                      <p className="px-1 reg-text text-white" style={{ lineHeight: '1', fontSize: '2.5em' }}>We're building an all-in-one solution to help Muslims around the world.</p></> :
                    <>
                      <p className="px-8 reg-text text-white" style={{ lineHeight: '1', fontSize: '2.5em' }}>We're building an all-in-one solution to help Muslims around the world.</p>
                    </>
                }
                <a href="https://apps.apple.com/us/app/imamai-ask-the-quran/id6450143012" target="_blank">
                  <img src={apple} width="200"></img>
                </a>
                <img src={android} width="250"></img>
                <br />
                <br />
                {/* <a href="https://www.imamai.app/store-2-qzjt0/p/imamai-app/p/imamai-app">
                  <Button className="px-5 py-3 rounded-pill green-text">Pre-order today for $5!</Button>
                </a> */}
              </div>
            </Card>
          </Container>
        </div>
      </div>
      <div style={{ borderTop: '2px solid #67B646' }}>
        <Container className="p-3">
          <Row>
            <Col className="green-text text-left reg-text">
              <img src={logoTrans} width="30%"></img>
              <br />
              <br />
              An app for the Muslim Community
              <br />
              built by the team @ LabThree
            </Col>
            <Col className="green-text text-right heavy-text">
              <br />
              <br />
              <a className="green-text" href="https://twitter.com/ImamAIapp">Twitter</a><br />
              <a className="green-text" href="https://www.facebook.com/profile.php?id=100093295447771">Facebook</a><br />
              <a className="green-text" href="https://www.instagram.com/imamaiapp/">Instagram</a><br />
              <a className="green-text" href="https://www.tiktok.com/@imamaiapp">TikTok</a><br />
              <a href="/privacy-notice.pdf" className="green-text" target="_blank" rel="noopener noreferrer">Privacy Notice</a><br />
              <a href="/terms-of-use.pdf" className="green-text" target="_blank" rel="noopener noreferrer">Terms of Use</a><br />
            </Col>
          </Row>
        </Container>

      </div>
    </ParallaxProvider>
  )
}

export default Home;
