import '../App.css'
import React, { useState, useEffect } from 'react';
import { Container, CardDeck, Card, Row, Col, Button, Navbar, Nav, NavbarBrand, NavItem, NavLink, NavbarToggler, Collapse } from 'reactstrap';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';


import backgroundImage from '../images/mosque-hero.png';
import logoTrans from '../images/logo-trans.png';
import logoIcon from '../images/icon.png'
import quranAiPreview from '../images/quran-ai-preview.png'
import virtualImamPreview from '../images/virtual-imam-preview.png'
import foodScanner from '../images/food-scanner.png'
import qiblaCompass from '../images/qibla-compass.png'
import tasbih from '../images/tasbih.png'
import prayerTime from '../images/prayer-time.png'
import dailyDua from '../images/daily-dua.png'
import mosqueFinder from '../images/mosque-finder.png'
import discoverPreview from '../images/discover-preview.png'
import halalCheck from '../images/halal-check.png'
import apple from '../images/app-store-pre-order.png'
import android from '../images/android-coming-soon.png'
const TopNav = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <>
            <div style={{ borderBottom: '2px solid #67B646' }}>
                <Navbar light expand="md" >
                    <NavbarBrand>
                        <img src={logoIcon} style={{ transform: 'scale(1.8)' }}></img>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <NavLink type="button">
                                    <span
                                        onClick={() => document.getElementById('askTheQuran').scrollIntoView({ behavior: 'smooth' })}
                                        className="text-black font-weight-bold reg-text">
                                        QuranAI
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink type="button">
                                    <span
                                        onClick={() => document.getElementById('getGuidance').scrollIntoView({ behavior: 'smooth' })}
                                        className="text-black font-weight-bold reg-text">Virtual Imam </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink type="button">
                                    <span
                                        onClick={() => document.getElementById('featureRich').scrollIntoView({ behavior: 'smooth' })}
                                        className="text-black font-weight-bold reg-text">Features </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                {/* <Link to="/demo-starter">
                  <NavLink type="button">
                    <span
                      className="text-black font-weight-bold reg-text">Demo </span>
                  </NavLink>
                </Link> */}
                            </NavItem>
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            {/* <a href="https://www.imamai.app/waitlist" target="_blank">
                <Button className="font-weight-bold reg-text rounded-pill green-bg">Get notified!</Button>
              </a> */}
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        </>
    );
}


const Privacy = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        // Return a cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []); // Empty array means this effect runs once on mount and clean up on unmount


    return (
        <>
            <div>
                <TopNav />
                <div className="text-center pt-7 pb-0 mosque-bg">
                    <Container style={{ width: '30vw' }}>
                        <p className="pt-5 text-black light-text" style={{ fontSize: '3em', lineHeight: '1.2' }}>ImamAI </p>
                        <p className="text-black light-text" style={{ fontSize: '1.3em' }}>Privacy Policy </p>
                        <br /><br />
                    </Container>
                </div>
                <div style={{ backgroundColor: "white" }}>
                </div>
            </div>
            <div style={{ borderTop: '2px solid #67B646' }}>
                <Container className="p-3">
                    <Row>
                        <Col className="green-text text-left reg-text">
                            <img src={logoTrans} width="30%"></img>
                            <br />
                            <br />
                            An app for the Muslim Community
                            <br />
                            built by the team @ LabThree
                        </Col>
                        <Col className="green-text text-right heavy-text">
                            <br />
                            <br />
                            <a className="green-text" href="https://twitter.com/ImamAIapp">Twitter</a><br />
                            <a className="green-text" href="https://www.facebook.com/profile.php?id=100093295447771">Facebook</a><br />
                            <a className="green-text" href="https://www.instagram.com/imamaiapp/">Instagram</a><br />
                            <a className="green-text" href="https://www.tiktok.com/@imamaiapp">TikTok</a><br />
                            <a href="/privacy-notice.pdf" className="green-text" target="_blank" rel="noopener noreferrer">Privacy Notice</a><br />
                            <a href="/terms-of-use.pdf" className="green-text" target="_blank" rel="noopener noreferrer">Terms of Use</a><br />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Privacy;
